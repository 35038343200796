import { useEffect } from "react";
import { Flex, Select } from "antd";

import { useLogs } from "../../providers/LogProvider";
import { Logs } from "./logs";

export const Dashboard = () => {
  const { activeApp, onAppChange, apps } = useLogs();

  if (apps.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Flex justify="space-between" style={{ margin: "0 0 10px" }}>
        <Select
          placeholder="Select App"
          style={{ minWidth: 180 }}
          defaultValue={activeApp}
          onChange={(appId) => onAppChange(appId, apps)}
        >
          {apps.map((el) => (
            <Select.Option key={el.id} value={el.id}>
              {el.name}
            </Select.Option>
          ))}
        </Select>
      </Flex>
      {apps.length > 0 && <Logs />}
    </>
  );
};
